import {Brand} from "../model/Brand";
import {Origin} from "../model/Origin";

export const getGoogleAnalytics = (location: string, userId: string, nwPropertyId?: string, pnsPropertyId?: string, brand?: string): string => {
    let trackingId = nwPropertyId;
    if (brand === Brand.PAK_N_SAVE) {
        trackingId = pnsPropertyId;
    }
    let info = `v=1&tid=${trackingId}&cid=${userId}&uid=${userId}&cd1=${userId}&cd25=${userId}&t=pageview&ul=en-gb&de=UTF-8&cd5=collect`;
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width && height) {
        info += `&vp=${width}x${height}`;
    }
    if (location) {
        info += `&dl=${location}`;
        if(location.indexOf("register") > -1) {
            info += `&dt=Register&dp=/register`;
        } else {
            info += `&dt=List`;
        }
    }
    const dateTime = new Date();
    info += `&cd4=${dateTime.getFullYear()}-${dateTime.getMonth() + 1}-${dateTime.getDate()}T${dateTime.toLocaleTimeString()}`;
    return info;
}

export const getRedirectUrl = (brand: string,
                               nwRedirectUrl: string | undefined,
                               pnsRedirectUrl: string | undefined,
                               isCardAdded: boolean,
                               from: string,
                               providedRedirectUrl: string | undefined): string => {
    if (isCardAdded) {
        if (from !== "" && from !== Origin.CHECK_OUT) {
            return "/"; // Back to list page after add card if redirected from manage cards
        }
    }
    if (brand === Brand.PAK_N_SAVE && pnsRedirectUrl) {
        return  providedRedirectUrl ?? pnsRedirectUrl;
    } else if (brand === Brand.NEW_WORLD && nwRedirectUrl) {
        return providedRedirectUrl ?? nwRedirectUrl;
    } else {
        return "/";
    }
}