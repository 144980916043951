import React, {useEffect, useState} from "react";
import {devLogger} from "../util/loggingUtils";
import {useParams} from "react-router";
import {useNavigate, useSearchParams} from "react-router-dom";
import {completeAddCard3DS2Flow} from "../client/paymentEdge";
import LoadingMessage from "../component/LoadingMessage";
import {useConfig} from "../context/configContext";
import {CardStatus} from "../model/CardStatus";
import Button from "react-bootstrap/Button";
import AlertMessage from "../component/AlertMessage";
import {mapErrorCodeToMessage} from "../util/errorUtils";

const AddCardComplete = () => {
    const { apiToken, handleRedirect} = useConfig();
    const {notificationId} = useParams()
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<CardStatus>();
    const resultId = searchParams.get("result_id") || "";
    const transactionNo = searchParams.get("transaction_no") || "";
    const [message, setMessage] = useState("");
    const [messageLevel, setMessageLevel] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const getAddCardStatus = async () => {
            try {
                const cardAddResult: CardStatus = await completeAddCard3DS2Flow(notificationId, apiToken, resultId, transactionNo);
                setLoading(false);
                setResult(cardAddResult);

                if (cardAddResult?.status === "SUCCESSFUL") {
                    navigate("/");
                } else {
                    setMessage(mapErrorCodeToMessage(cardAddResult.errorCode as string));
                    setMessageLevel("warning");
                }
            } catch (error: any) {
                setMessage(error?.message);
                setMessageLevel(error?.messageLevel);
                setTokenExpired(error.tokenExpired || false)
            } finally {
                setLoading(false);
            }
        }

        if (resultId && notificationId && !result) {
            devLogger("got the expected response, use it!! ");
            getAddCardStatus()
        }

    }, [apiToken, navigate, notificationId, result, resultId, transactionNo]);
    return (
        <>
            <h2 className="title" style={{fontWeight: 900}}>Add card status</h2>
            {loading &&
                <LoadingMessage />
            }
            {!loading && result?.status !== "SUCCESSFUL" &&
                <>
                    {message && messageLevel &&
                        <AlertMessage message={message} messageLevel={messageLevel}/>
                    }
                    {!tokenExpired && !loading
                        ? <Button variant="primary" onClick={() => {navigate("/")}}>OK</Button>
                        : <Button variant="primary" onClick={() => {handleRedirect(false)}}>OK</Button>
                    }
                </>
            }

        </>
    );
}
export default AddCardComplete;