// @flow
import React, {useContext, useEffect, useState} from "react";
import {Brand} from "../model/Brand";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import {getGoogleAnalytics, getRedirectUrl} from "../util/pageUtils";
import {loginUserUsingSecureLookup, getFeatureFlags} from "../client/paymentEdge";
import {getConfiguration} from "../config/config";
import {useSearchParams} from "react-router-dom";
import {FeatureFlags} from "../model/FeatureFlags";
import {devLogger} from "../util/loggingUtils";

type UserInfo = {
    sub: string
}

const config = getConfiguration();

export const ConfigContext = React.createContext({
    brand: Brand.NEW_WORLD,
    appReturnUrl: "",
    apiToken: "",
    configInitialised: false,
    appVersion: "",
    gitHash: "",
    resolveFeatureFlag: (string): boolean => {return false},
    handleRedirect: (boolean) => {},
    postGoogleAnalytics: () => {}
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({children}) => {
  const tokenCookieKey = "API_TOKEN";
  const appReturnUrlCookieKey = "APP_RETURN_URL";
  const lookupKeyQueryParam = "lookupKey";
  const redirectUrlQueryParam = "redirectUrl";

  const [searchParams, setSearchParams] = useSearchParams();
  const lookupKey = searchParams.get(lookupKeyQueryParam) || "";
  const redirectUrl = searchParams.get(redirectUrlQueryParam) || "";

  const isDevelopment = config.isDevelopment;

  const [brand, setBrand] = useState<Brand>(Brand.NEW_WORLD);
  const [appReturnUrl, setAppReturnUrl] = useState<string>(redirectUrl);
  const [apiToken, setApiToken] = useState<string>("");
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(
      {flags: new Map(), resolvedFromServer: false}
  );

  const [configInitialised, setConfigInitialised] = useState(false);

  const paymentEdgeHost = config.paymentEdgeHost;
  const pnsRedirectUrl  = config.pnsRedirectUrl;
  const nwRedirectUrl  = config.nwRedirectUrl;
  const pnsPropertyId = config.pnsPropertyId;
  const nwPropertyId = config.nwPropertyId;
  const gaUrl = config.gaUrl;
  const appVersion = config.appVersion;
  const gitHash = config.gitHash;

  // Checking if token is set as cookie, so initially coming from web
  const tokenFromCookie = Cookies.get(tokenCookieKey) || "";
  const from = Cookies.get("RETURN_PAGE_IDENTIFIER");

  useEffect(() => {
      const resolveFeatureToggles = async (apiToken) => {
          if (apiToken.length > 0 && !featureFlags.resolvedFromServer) {
              try {
                  const resolvedFeatureFlags: FeatureFlags = await getFeatureFlags(apiToken);
                  devLogger(JSON.stringify(resolvedFeatureFlags));
                  setFeatureFlags(resolvedFeatureFlags);
              } catch (error) {
                  // if error thrown I think we want to just set an empty array of flags and continue
                  setFeatureFlags({flags: new Map(), resolvedFromServer: true})
              }
          }
      }
    const initConfig = async () => {
        let iconName = "mnw";
        const cookieConfig = {
            sameSite: "Strict"
        }
        if (window.location.href.indexOf("paknsave") > -1) {
            setBrand(Brand.PAK_N_SAVE);
            iconName = "pak";
        }
        const favicon = document.getElementById("favicon") as HTMLAnchorElement;
        if (favicon) {
            favicon.href = `/images/favicon/${iconName}.ico`;
        }

        if (redirectUrl) {
            setAppReturnUrl(redirectUrl);
            Cookies.set(appReturnUrlCookieKey, redirectUrl, cookieConfig);
        } else {
            setAppReturnUrl(Cookies.get(appReturnUrlCookieKey) || "");
        }
        if (lookupKey) {
            try {
                const loggedIn = await loginUserUsingSecureLookup(lookupKey)
                setApiToken(loggedIn);
                // Only required to clean up token for developer's sake, as switching between
                // local and via website causes issues as their ends up being multiple API_TOKEN
                // cookies.
                Cookies.remove(tokenCookieKey);
                Cookies.set(tokenCookieKey, loggedIn, cookieConfig);
            } catch (error) {
                setApiToken(tokenFromCookie);
            }
        } else {
            setApiToken(tokenFromCookie);
        }

        if (searchParams.has(redirectUrlQueryParam)) {
            searchParams.delete(redirectUrlQueryParam)
        }
        if (searchParams.has(lookupKeyQueryParam)) {
            searchParams.delete(lookupKeyQueryParam);
        }
        setSearchParams(searchParams, {replace: true});
        setConfigInitialised(true);
    };

    if (configInitialised) {
        resolveFeatureToggles(apiToken);
    } else {
        initConfig()
    }
  }, [
            paymentEdgeHost,
            lookupKey,
            redirectUrl,
            tokenFromCookie,
            searchParams,
            setSearchParams,
            configInitialised,
            featureFlags,
            apiToken
      ]
  );

  const handleRedirect = (isAddCard: boolean) => {
      window.location.href = getRedirectUrl(brand, nwRedirectUrl, pnsRedirectUrl, isAddCard, from, appReturnUrl);
  }

  const postGoogleAnalytics = () => {
      const isGoogleAnalyticsEnabled = resolveFeatureFlag("enable_google_analytics");
      if (brand && apiToken && !isDevelopment && isGoogleAnalyticsEnabled) {
          const decode = jwtDecode(apiToken) as UserInfo;
          let info = getGoogleAnalytics(window.location.href, decode.sub, nwPropertyId, pnsPropertyId, brand);
          fetch(gaUrl as string, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
              body: new URLSearchParams(info)
          })
      }
  }

  const resolveFeatureFlag = (featureFlag: string) : boolean => {
      return featureFlags?.flags[featureFlag] ? featureFlags.flags[featureFlag] : false;
  }

  return (
    <ConfigContext.Provider
      value={{
        brand,
        appReturnUrl,
        apiToken,
        configInitialised,
        appVersion,
        gitHash,
        resolveFeatureFlag,
        handleRedirect,
        postGoogleAnalytics
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};